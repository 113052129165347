define("discourse/plugins/rstudio-community-discourse/discourse/connectors/composer-fields/announcements-composer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    setupComponent(attrs, ctx) {
      const model = attrs.model;
      const settings = ctx.siteSettings;
      ctx.set('showInputs', model.categoryId == settings.ads_category);
      model.addObserver('categoryId', () => {
        if (this._state === 'destroying') return;
        ctx.set('showInputs', model.categoryId == settings.ads_category);
      });
    }
  };
});