define("discourse/plugins/rstudio-community-discourse/discourse/initializers/ad-url-edits", ["exports", "discourse/models/composer"], function (_exports, _composer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: 'ad-url-edits',
    initialize(container) {
      _composer.default.serializeOnCreate('ad_url');
      _composer.default.serializeToTopic('ad_url', 'topic.ad_url');
      _composer.default.serializeOnCreate('show_images');
      _composer.default.serializeToTopic('show_images', 'topic.show_images');
    }
  };
});