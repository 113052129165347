define("discourse/plugins/rstudio-community-discourse/discourse/connectors/topic-category/announcements-topic", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    shouldRender(attrs, ctx) {
      return attrs.topic.ad_url && attrs.topic.category_id == ctx.siteSettings.ads_category;
    }
  };
});