define("discourse/plugins/rstudio-community-discourse/discourse/templates/connectors/topic-category/announcements-topic", ["exports", "ember-this-fallback/this-fallback-helper", "ember-this-fallback/try-lookup-helper", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _thisFallbackHelper, _tryLookupHelper, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <span>
    {{i18n 'announcements.ad_url'}}
  </span>
  <span class="ad-url">
    <a href="{{topic.ad_url}}" target="_blank">{{topic.ad_url}}</a>
  </span>
  
  */
  {
    "id": "7MY19e6c",
    "block": "[[[10,1],[12],[1,\"\\n  \"],[1,[28,[35,0],[\"announcements.ad_url\"],null]],[1,\"\\n\"],[13],[1,\"\\n\"],[10,1],[14,0,\"ad-url\"],[12],[1,\"\\n  \"],[44,[[28,[37,2],null,[[\"topic\"],[[28,[32,0],[\"topic\"],null]]]]],[[[10,3],[15,6,[29,[[52,[30,1,[\"topic\"]],[28,[30,1,[\"topic\"]],null,null],[28,[32,1],[[30,0],\"topic.ad_url\",\"[\\\"The `topic` property path was used in the `discourse/plugins/rstudio-community-discourse/discourse/templates/connectors/topic-category/announcements-topic.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.topic}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]\"],null]]]]],[14,\"target\",\"_blank\"],[12],[1,[30,0,[\"topic\",\"ad_url\"]]],[13]],[1]]],[1,\"\\n\"],[13],[1,\"\\n\"],[1,[28,[32,2],[\"[[\\\"The `topic` property path was used in the `discourse/plugins/rstudio-community-discourse/discourse/templates/connectors/topic-category/announcements-topic.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.topic}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[\"maybeHelpers\"],false,[\"i18n\",\"let\",\"hash\",\"if\"]]",
    "moduleName": "discourse/plugins/rstudio-community-discourse/discourse/templates/connectors/topic-category/announcements-topic.hbs",
    "scope": () => [_tryLookupHelper.default, _thisFallbackHelper.default, _deprecationsHelper.default],
    "isStrictMode": false
  });
});